<template>
  <div style="padding: 0px">
    <div style="display: flex; padding: 0px 0; justify-content: space-between">
      <div style="display: flex">
        <el-select
          v-model="form.chooseType"
          clearable
          style="margin-left: 0px"
          placeholder="请选择分类"
        >
          <el-option
            v-for="item in PlanData"
            :key="item.Id"
            :label="item.label || '1'"
            :value="item.Id"
          >
          </el-option>
        </el-select>

        <!-- <el-select
        v-model="value"
        style="margin-left: 0px"
        placeholder="请选择教案名称"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
      >
        重置
      </div> -->

        <div
          style="
            width: 110px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background-color: #01b37c;
            color: #fff;
            border-radius: 5px;
            font-size: 16px;
            margin-left: 20px;
            cursor: pointer;
          "
          @click="openAddClassFn"
        >
          新建分类
        </div>
      </div>

      <el-button
        @click="clickLookCheck"
        style="background-color: transparent; opacity: 0"
        >666</el-button
      >

      <div
        v-if="false"
        style="
          width: 110px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
        @click="openEditClassFn"
      >
        编辑分类
      </div>
      <div
        v-if="false"
        style="
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #f78989;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="DeleteTeachPlanFn"
      >
        删除当前分类
      </div>
    </div>

    <div
      v-if="pointCheck"
      style="font-size: 12px; color: red; margin-top: 3px; margin-left: 3px"
    >
      请选择分类
    </div>

    <div style="display: flex; margin-top: 20px">
      <span>类型：</span>
      <img
        style="width: 20px; height: 20px; margin-right: 5px"
        src="http://image.mengqiyun.com/%E5%AE%9E%E4%BE%8B%E7%B1%BB%E5%9E%8B.png"
        alt=""
      />
      <span style="margin: 0 0 0 30px">教案：</span>
      <img
        style="width: 20px; height: 20px; margin-right: 5px"
        src="http://image.mengqiyun.com/%E5%A4%87%E8%AF%BE%E6%95%99%E6%A1%88.png"
        alt=""
      />
    </div>

    <div
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
      "
    >
      <div
        v-for="(item, index) in teachData"
        :key="index"
        style="
          width: 49%;
          background-color: #f7f9fa;
          margin-bottom: 20px;
          padding-bottom: 0px;
          min-height: 60px;
          padding-bottom: 20px;
        "
      >
        <div
          v-if="false"
          style="
            height: 60px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div v-if="false" style="font-size: 16px; font-weight: bold">
            <!-- 666 -->

            类型：{{ item.label }}
          </div>
          <div v-if="false" style="display: flex">
            <div
              style="
                width: 70px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #fff;
                color: #01b37c;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                border: 1px solid #01b37c;
                box-sizing: border-box;
                cursor: pointer;
              "
              @click="openEditClassFn(item)"
            >
              编辑
            </div>
            <div
              style="
                width: 70px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #fff;
                color: #eb5b5b;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                border: 1px solid #eb5b5b;
                box-sizing: border-box;
                cursor: pointer;
              "
              @click="DeleteTeachPlanFn(item)"
            >
              删除
            </div>
            <div
              style="
                width: 98px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #01b37c;
                color: #fff;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                cursor: pointer;
              "
              @click="openUploadFn(item)"
            >
              上传教案
            </div>
          </div>
        </div>

        <!-- <el-tree
          :data="teachData[0]"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree> -->

        <el-tree
          v-if="index < teachData.length - 1"
          style="
            background-color: #f7f9fa;
            padding-left: 10px;
            padding-top: 20px;
          "
          :data="item"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
          <span
            class="custom-tree-node show-hide"
            style="margin-top: 5px"
            slot-scope="{ node, data }"
          >
            <div style="display: flex">
              <img
                style="width: 20px; height: 20px; margin-right: 5px"
                v-if="data.FileName"
                src="http://image.mengqiyun.com/%E5%A4%87%E8%AF%BE%E6%95%99%E6%A1%88.png"
                alt=""
              />
              <img
                style="width: 20px; height: 20px; margin-right: 5px"
                v-if="!data.FileName"
                src="http://image.mengqiyun.com/%E5%AE%9E%E4%BE%8B%E7%B1%BB%E5%9E%8B.png"
                alt=""
              />
              <span>{{ node.label }}</span>
              <span v-if="lookCheck == true">({{ data.Id }})</span>
            </div>

            <span style="display: none">
              <!-- {{ data.Id }} -->
              <!-- v-if="!data.AddTime && data.label.indexOf('月') == -1" -->

              <!-- 修改分类 -->
              <i
                v-if="!data.FileName"
                class="el-icon-edit"
                @click="openEditClassFn6(data, index)"
                style="font-size: 24px; margin-right: 10px; color: #01b37c"
                title="编辑"
              ></i>
              <!-- {{ data.isLeaf }} -->
              <i
                v-if="data.isLeaf >= 0 && data.isLeaf <= 2"
                @click="openAddClassFn(data, index)"
                class="el-icon-plus"
                style="
                  font-size: 24px;
                  margin-right: 0px;
                  color: #409eff;
                  margin-right: 10px;
                "
                title="添加类型"
              ></i
              ><!--增加节点-->

              <i
                v-if="!data.FileName"
                @click="openUploadFn(data, index, data.Id, data.label)"
                class="el-icon-upload"
                style="font-size: 24px; margin-right: 0px; color: #01b37c"
                title="上传教案"
              ></i
              ><!--增加节点-->
              <!-- 根节点不需要删除和修改 -->
              <i
                v-if="data.AddTime"
                class="el-icon-edit"
                @click="openEditSmallFn(data, index)"
                style="font-size: 24px; margin-right: 10px; color: #01b37c"
                title="编辑"
              ></i>
              <i
                v-if="data.AddTime"
                class="el-icon-delete"
                @click="DeletePlanDetailFn(data, index)"
                style="font-size: 24px; margin-right: 0px; color: #f56c6c"
                title="删除"
              ></i
              ><!--删除小教案-->

              <!-- 删除大类型 -->
              <i
                v-if="!data.FileName"
                class="el-icon-delete"
                @click="DeleteTeachPlanFn(data, index)"
                style="font-size: 24px; margin-left: 5px; color: #f56c6c"
                title="删除"
              ></i
              ><!--删除节点-->
            </span>
          </span>
        </el-tree>

        <div
          v-if="index == teachData.length - 1"
          style="
            padding-top: 20px;
            padding-left: 20px;
            box-sizing: border-box;
            padding-right: 20px;
          "
        >
          <div>
            <div
              style="
                width: 98px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #01b37c;
                color: #fff;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 0px;
                cursor: pointer;
              "
              @click="openUploadFn7(item, index)"
            >
              上传教案
            </div>

            <div
              v-for="(uu, inx) in teachData[teachData.length - 1][0].teachPlans"
              :key="inx"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
                font-size: 16px;
              "
            >
              <div>{{ uu.FileName }}</div>
              <div>
                <i
                  class="el-icon-edit"
                  @click="openEditSmallFn10(uu, index)"
                  style="font-size: 24px; margin-right: 10px; color: #01b37c"
                  title="编辑"
                ></i>
                <i
                  class="el-icon-delete"
                  @click="DeletePlanDetailFn2(uu, index)"
                  style="font-size: 24px; margin-right: 0px; color: #f56c6c"
                  title="删除"
                ></i
                ><!--删除小教案-->
              </div>
            </div>
          </div>
        </div>

        <!-- 之前 -->
        <el-tree
          v-if="false"
          style="background-color: #f7f9fa; padding-left: 10px"
          :data="teachData[index].treeData"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>

            <span style="display: none">
              <!-- {{ data.label }} -->

              <i
                v-if="!data.AddTime && data.label.indexOf('月') == -1"
                @click="
                  openUploadFn(data, index, data.year, data.month, data.week)
                "
                class="el-icon-plus"
                style="font-size: 24px; margin-right: 0px; color: #409eff"
                title="上传教案"
              ></i
              ><!--增加节点-->
              <!-- 根节点不需要删除和修改 -->
              <i
                v-if="data.AddTime"
                class="el-icon-edit"
                @click="openEditSmallFn(data, index)"
                style="font-size: 24px; margin-right: 10px; color: #01b37c"
                title="编辑"
              ></i>
              <i
                v-if="data.AddTime"
                class="el-icon-delete"
                @click="DeletePlanDetailFn(data, index, data.Id)"
                style="font-size: 24px; margin-right: 0px; color: #f56c6c"
                title="删除"
              ></i
              ><!--删除节点-->
            </span>
          </span>
        </el-tree>

        <div
          v-for="(val, index) in item.children"
          :key="index"
          style="
            width: 100%;
            padding: 0 20px 0 40px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #f7f8fa;
            box-sizing: border-box;
            align-items: center;
          "
        >
          <div style="display: flex; align-items: center">
            <div style="font-size: 18px">{{ val.FileName }}</div>
            <!-- <div
              style="
                font-size: 14px;
                color: #666;
                margin-left: 40px;
                margin-right: 20px;
              "
            >
              10M
            </div> -->
            <div style="font-size: 14px; color: #666; margin-left: 20px">
              {{ val.AddTime }}
            </div>
          </div>

          <div style="display: flex; align-items: center; padding-bottom: 0">
            <div
              style="
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #fff;
                color: #01b37c;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                border: 1px solid #01b37c;
                box-sizing: border-box;
                cursor: pointer;
              "
              @click="openEditSmallFn(val, index)"
            >
              编辑
            </div>
            <div
              style="
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #fff;
                color: #eb5b5b;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                border: 1px solid #eb5b5b;
                box-sizing: border-box;
                cursor: pointer;
              "
              @click="DeletePlanDetailFn(val)"
            >
              删除
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建教案 -->
    <el-dialog title="新建分类" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              分类名称
            </div>

            <el-input
              v-model="form.typeName"
              placeholder="请输入分类名称"
              style="flex: 1"
            />

            <!-- <el-select style="flex: 1" v-model="form.Year" placeholder="选择年">
              <el-option
                v-for="item in YearData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select
              style="flex: 1"
              v-model="form.Month"
              placeholder="选择月"
            >
              <el-option
                v-for="item in MonthData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select style="flex: 1" v-model="form.Week" placeholder="选择周">
              <el-option
                v-for="item in WeekData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offAddClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddTeachPlanFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 修改教案 -->
    <el-dialog title="修改分类" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              分类名称
            </div>

            <el-input
              v-model="form.typeName"
              placeholder="请输入分类名称"
              style="flex: 1"
            />
            <!-- 
            <el-select style="flex: 1" v-model="form.typeName" placeholder="选择年">
              <el-option
                v-for="item in YearData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select
              style="flex: 1"
              v-model="form.Month"
              placeholder="选择月"
            >
              <el-option
                v-for="item in MonthData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select style="flex: 1" v-model="form.Week" placeholder="选择周">
              <el-option
                v-for="item in WeekData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offEditClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateTeachPlanFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 上传教案 0 -->
    <el-dialog title="上传教案" :visible.sync="dialogVisible7" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案名称
            </div>
            <el-input
              v-model="form.FileName"
              style="flex: 1"
              placeholder="请输入教案名称"
            />
          </div>
        </div>

        <!-- 教案图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in imgData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="imgData"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in HomeWorkData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delHomeImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div v-if="false">
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="HomeWorkUrl != ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-top: 3px;
                    border-radius: 5px;
                  "
                  :src="HomeWorkUrl"
                />

                <div
                  v-if="HomeWorkUrl == ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                    z-index: 999;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 确定 -->
        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="offUploadFn7"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddPlanDetailFn7"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 上传教案 123 -->
    <el-dialog title="上传教案" :visible.sync="dialogVisible3" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案名称
            </div>
            <el-input
              v-model="form.FileName"
              style="flex: 1"
              placeholder="请输入教案名称"
            />
          </div>
        </div>

        <!-- 教案图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in imgData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="imgData"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in HomeWorkData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delHomeImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div v-if="false">
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="HomeWorkUrl != ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-top: 3px;
                    border-radius: 5px;
                  "
                  :src="HomeWorkUrl"
                />

                <div
                  v-if="HomeWorkUrl == ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                    z-index: 999;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 确定 -->
        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="offUploadFn"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddPlanDetailFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑教案 -->
    <el-dialog title="编辑教案" :visible.sync="dialogVisible4" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案名称
            </div>
            <el-input
              v-model="form.FileName"
              style="flex: 1"
              placeholder="请输入教案名称"
            />
          </div>
        </div>

        <!-- 教案图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in imgData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="imgData"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in HomeWorkData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delHomeImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业 -->
        <div v-if="false">
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="HomeWorkUrl != ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-top: 3px;
                    border-radius: 5px;
                  "
                  :src="HomeWorkUrl"
                />

                <div
                  v-if="HomeWorkUrl == ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                    z-index: 999;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="offEditSmallFn"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdatePlanDetailFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑教案 -->
    <el-dialog title="编辑教案" :visible.sync="dialogVisible10" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案名称
            </div>
            <el-input
              v-model="form.FileName"
              style="flex: 1"
              placeholder="请输入教案名称"
            />
          </div>
        </div>

        <!-- 教案图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              教案图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in imgData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="imgData"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业图片 -->
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in HomeWorkData"
                :key="index"
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                "
              >
                <img
                  :src="item"
                  style="width: 105px; height: 105px; border-radius: 5px"
                  alt=""
                />
                <el-button
                  icon="el-icon-close"
                  circle
                  @click="delHomeImgData(index)"
                  class="pobutton"
                ></el-button>
              </div>

              <!-- <div
                style="
                  width: 105px;
                  height: 105px;
                  margin-right: 0px;
                  border: 1px solid #01b37c;
                  box-sizing: border-box;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 105px;
                  font-size: 28px;
                  color: #01b37c;
                "
              >
                <span class="el-icon-plus"></span>
              </div> -->

              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img
                  style="width: 105px; height: 105px;margin-top: 3px;"
                  :src="uploadBannerUrl"
                /> -->

                <div
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <!-- 作业 -->
        <div v-if="false">
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              作业图片
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <el-upload
                style="
                  font-size: 32px;
                  color: #999;
                  width: 105px;
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: center;
                "
                class="avatar-uploader"
                action="http://175.178.60.146:8084/api/Auth/UploadImage"
                :show-file-list="false"
                :file-list="HomeWorkData"
                :headers="headers"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="HomeWorkUrl != ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-top: 3px;
                    border-radius: 5px;
                  "
                  :src="HomeWorkUrl"
                />

                <div
                  v-if="HomeWorkUrl == ''"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-right: 0px;
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 105px;
                    font-size: 28px;
                    color: #e4e7ed;
                    z-index: 999;
                  "
                >
                  <span class="el-icon-plus"></span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="offEditSmallFn10"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdatePlanDetailFn10"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 课程编辑 -->
    <el-dialog title="课程编辑" :visible.sync="dialogVisible5" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              课程名称
            </div>
            <el-input disabled style="flex: 1" placeholder="请输入课程名称" />
          </div>
        </div>

        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              修改名称
            </div>
            <el-input style="flex: 1" placeholder="请输入课程名称" />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offEditClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 修改分类 教案 -->
    <el-dialog title="修改分类" :visible.sync="dialogVisible6" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              分类名称
            </div>

            <el-input
              v-model="form.typeName"
              placeholder="请输入分类名称"
              style="flex: 1"
            />
            <!-- 
            <el-select style="flex: 1" v-model="form.typeName" placeholder="选择年">
              <el-option
                v-for="item in YearData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select
              style="flex: 1"
              v-model="form.Month"
              placeholder="选择月"
            >
              <el-option
                v-for="item in MonthData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select style="flex: 1" v-model="form.Week" placeholder="选择周">
              <el-option
                v-for="item in WeekData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offEditClassFn6"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateTeachPlanFn6"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  // GetAllTeachPlan,
  AddTeachPlan,
  UpdateTeachPlan,
  DeleteTeachPlan,
  UpLoadTeachPlan,
  AddPlanDetail,
  UpdatePlanDetail,
  DeletePlanDetail,
  GetAllPlanDetail,
  GetAllTeachPlan, //获取所有分类
  GetAllTeachPlanByOne, //获取分类教案详情
} from "@/request/api.js";

export default {
  name: "buyHistory",
  data() {
    return {
      lookCheck: false,
      login: true,
      pointCheck: false,
      data2: [
        {
          label: "666",
          children: [
            {
              label: "666666",
              children: [],
            },
            {
              label: "666666",
              children: [],
            },
          ],
        },
      ],
      historyTable: [],

      pageIndex: 1,
      pageSize: 10,
      //教案
      options: [
        {
          Id: "1",
          label: "分类1",
        },
        {
          Id: "2",
          label: "分类2",
        },
        {
          Id: "3",
          label: "分类3",
        },
        {
          Id: "4",
          label: "分类4",
        },
        {
          Id: "5",
          label: "分类5",
        },
        {
          Id: "6",
          label: "分类6",
        },
        {
          Id: "7",
          label: "分类7",
        },
        {
          Id: "8",
          label: "分类8",
        },
        {
          Id: "9",
          label: "分类9",
        },
        {
          Id: "10",
          label: "分类10",
        },
        {
          Id: "11",
          label: "分类11",
        },
        {
          Id: "12",
          label: "分类12",
        },
      ],
      value: "",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible10: false,
      StartMonth: "",
      StartWeek: "",
      form: {
        Year: "",
        Month: "",
        Week: "",
        FileName: "",
        typeName: "",
        chooseType: "",
        chooseTypeId: "",
        fatherId: "",
        PlanName: "",
        label: "",
        children: [],
        PlanId: "",
        editIndex: "",
        twoIndex: "",
        addTypeIndex: "",
        addbigType: false,
        uploadRow: "",
      },
      HomeWorkUrl: "", //上传作业图片
      teachData: [],
      YearData: [], //年份
      MonthData: [], //月份
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      imgData: [], //上传教案图片
      HomeWorkData: [], //上传作业图片
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      defaultProps: {
        children: "children",
        label: "label",
        AddTime: "AddTime",
        FileName: "FileName",
        HomeWorkUrl: "HomeWorkUrl",
        Year: "Year",
        Month: "Month",
        Week: "Week",
        Id: "Id",
        PictureUrl: "PictureUrl",
        // Id2: "Id2",
      },

      uploadForm: {
        uploadRow: "",
        uploadIndex: "",
        // uploadYear: "",
        // uploadMonth: "",
        // uploadWeek: "",
        uploadId: "",
        uploadName: "",
        editIndex: "",
      },
      PlanData: [],
      imageData: [],
      HomeData: [],
    };
  },
  //页面一加载执行
  created() {
    this.GetAllTeachPlanFn(); //获取所有教案
    // this.GetAllPlanDetailFn(); //获取所有教案
    let arr = [];
    let arr2 = [];
    for (let i = 1; i < 100; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;
  },

  watch: {
    "form.chooseType"(e) {
      if (e === "") {
        this.teachData = [];
      } else {
        this.GetAllTeachPlanByOneFn();
      }

      if (e === "") {
        this.pointCheck = true;
      } else {
        this.pointCheck = false;
      }
    },
  },
  methods: {
    // 教案
    // 删除图片
    delImgData(e) {
      this.imgData.splice(e, 1);
    },
    delHomeImgData(e) {
      this.HomeWorkData.splice(e, 1);
    },
    clickLookCheck() {
      this.lookCheck = !this.lookCheck;
    },

    // tree
    handleNodeClick(data) {
      console.log(data);
      // this.imgData = data.PictureUrl;
      // this.HomeWorkData = data.HomeWorkUrl;
    },

    //获取教案信息  暂时不用 /api/PlanDetail/GetAllPlanDetail
    GetAllPlanDetailFn() {
      let that = this;
      GetAllPlanDetail().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案==>", res.Data);
          // that.teachData = res.Data;
          // let tree = that.teachData;
          // that.teachData[0].treeData[0].children[0].children[0] ={label:'666',children:[]};
          // that.teachData[0].treeData[0].children[0].children[2] ={label:'777',children:[]};

          // that.teachData= tree;
          console.log("that.teachData==>", that.teachData);
          //获取所有教案循环  res.Data.length
          for (let i = 0; i < res.Data.length; i++) {
            //每个教案
            let element = res.Data[i];
            let Year = element.Year;
            let Month = element.Month - 1;
            let Week = element.Week - 1;
            // console.log("tree[Year].treeData[Month].children[Week]==>",
            // tree[Year].treeData[Month].children[Week].children.push({label:'666',children:[]})
            // );

            // if(tree[Year].treeData[Month].children[Week]){
            //   // console.log("");
            // }else{
            //   console.log("year==>",Year);
            //   console.log("Month==>",Month);
            //   console.log("Week==>",Week);
            //   console.log("无==>",tree[Year].treeData[Month].children);
            // }

            // return
            tree[Year].treeData[Month].children[Week].children.push({
              label: element.FileName,
              children: [],
              AddTime: element.AddTime,
              FileName: element.FileName,
              HomeWorkUrl: element.HomeWorkUrl,
              Year: element.Year,
              Month: element.Month,
              Week: element.Week,
              Id: element.Id,
              PictureUrl: element.PictureUrl,
              Id2: "-1",
            });
          }

          console.log("that.teachData ===>", that.teachData);
          console.log("tree ===>", tree);

          that.$nextTick(() => {
            that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
          });
        }
      });
    },

    //根据id获取教案分类详情   /api/TeachPlan/GetAllTeachPlanByOne
    GetAllTeachPlanByOneFn() {
      let that = this;
      GetAllTeachPlanByOne({ id: that.form.chooseType }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("根据id获取教案分类详情==>", res.Data);
          console.log("根据id获取教案分类详情==>", res.Data.teachPlans);

          let arr = [];

          //获取所有教案循环  res.Data.length
          for (let i = 0; i < res.Data.children.length; i++) {
            //每个教案
            let element = res.Data.children[i];
            console.log("element.children==>", element.children);

            arr[i] = [element];

            if (element.children === null) {
              // console.log("空");
              element.children = [];
            }
            if (element.teachPlans === null) {
              // console.log("空");
              element.teachPlans = [];
            }

            for (let h = 0; h < element.children.length; h++) {
              const element3 = element.children[h];
              console.log("element3==>", element3);
              for (let k = 0; k < element3.children.length; k++) {
                const element4 = element3.children[k];

                if (element4.fatherId != null) {
                  for (let j = 0; j < element4.teachPlans.length; j++) {
                    const element5 = element4.teachPlans[j];
                    console.log("element5==>", element5);
                    element4.children.push({
                      label: element5.FileName,
                      children: [],
                      AddTime: element5.AddTime,
                      FileName: element5.FileName,
                      HomeWorkUrl: element5.HomeWorkUrl,
                      Id: element5.Id,
                      PictureUrl: element5.PictureUrl,
                      PlanName: element5.PlanName,
                    });
                  }
                }
                console.log("element4==>", element4);
              }
              for (let k = 0; k < element3.teachPlans.length; k++) {
                const element4 = element3.teachPlans[k];
                console.log("element4==>", element4);
                element3.children.push({
                  label: element4.FileName,
                  children: [],
                  AddTime: element4.AddTime,
                  FileName: element4.FileName,
                  HomeWorkUrl: element4.HomeWorkUrl,
                  Id: element4.Id,
                  PictureUrl: element4.PictureUrl,
                  PlanName: element4.PlanName,
                });
                //   for (let m = 0; m < element4.teachPlans.length;m++) {
                //   const element5 = element4.teachPlans[m];
                //   element4.children.push({
                //     label: element5.FileName,
                //     children: [],
                //     AddTime: element5.AddTime,
                //     FileName: element5.FileName,
                //     HomeWorkUrl: element5.HomeWorkUrl,
                //     Id: element5.Id,
                //     PictureUrl: element5.PictureUrl,
                //     PlanName: element5.PlanName,
                //   });
                // }
              }

              if (element3.children === null) {
                // console.log("空");
                element3.children = [];
              }
              if (element3.teachPlans === null) {
                // console.log("空");
                element3.teachPlans = [];
              }
              //将element5.teachPlans循环放入element5.children

              // for (let p = 0; p < element3.children.length; p++) {
              //   const element5 = element3.children[p];
              //   console.log("element5==>", element5);

              // }
            }

            for (let j = 0; j < element.teachPlans.length; j++) {
              const element2 = element.teachPlans[j];

              // console.log("element2==>", element2);
              element.children.push({
                label: element2.FileName,
                children: [],
                AddTime: element2.AddTime,
                FileName: element2.FileName,
                HomeWorkUrl: element2.HomeWorkUrl,
                Id: element2.Id,
                PictureUrl: element2.PictureUrl,
                PlanName: element2.PlanName,
              });
            }

            // return
            // tree[Year].treeData[Month].children[Week].children.push({
            //   label: element.FileName,
            //   children: [],
            //   AddTime: element.AddTime,
            //   FileName: element.FileName,S
            //   HomeWorkUrl: element.HomeWorkUrl,
            //   Year: element.Year,
            //   Month: element.Month,
            //   Week: element.Week,
            //   Id: element.Id,
            //   PictureUrl: element.PictureUrl,
            //   Id2: "-1",
            // });
          }

          that.teachData = arr;

          that.teachData.push([
            {
              Id: "",
              children: res.Data.teachPlans,
              label: that.form.chooseType,
              teachPlans: res.Data.teachPlans,
              isLeaf: 0,
              fatherId: res.Data.Id,
            },
          ]);

          that.$nextTick(() => {
            that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
          });

          console.log("that.teachData ===>", that.teachData);
          console.log("data2 ===>", that.data2);
          console.log("arr ===>", arr);
        }
      });
    },

    //上传教案图片
    handleAvatarSuccess(res, file) {
      console.log("图片res==>", res);
      let arr = this.imgData;
      if (res.Code == 2000) {
        arr.push(res.Data.Url);
      } else {
        console.log("上传教案图片Message==>", res);
      }
      this.imgData = arr;
      console.log(this.imgData);
    },
    //上传作业图片
    handleAvatarSuccess2(res, file) {
      console.log("图片res==>", res);
      let arr = this.HomeWorkData;
      if (res.Code == 2000) {
        // this.HomeWorkUrl = res.Data.Url;
        arr.push(res.Data.Url);
      } else {
        console.log("上传教案作业图片Message==>", res);
      }
      this.HomeWorkData = arr;
    },
    beforeAvatarUpload() {
      //
    },

    //打开新建大级分类
    openAddClassFn(row, index) {
      console.log("打开新增类型==>", row, index);

      if (this.form.chooseType == "") {
        this.pointCheck = true;
        // this.$message({
        //   message: "请先选择分类",
        //   type: "error",
        // });
        return;
      }

      if (this.form.chooseType == "") {
        this.pointCheck = false;
      }

      if (row.Id) {
        this.form.fatherId = row.Id;
        this.uploadForm.uploadRow = row;
        this.uploadForm.uploadIndex = index;
        this.form.typeName = "";
        this.HomeWorkUrl = "";
        this.imgData = [];
      } else {
        this.form.fatherId = this.form.chooseType;
        this.form.typeName = "";
      }
      this.dialogVisible1 = true;
    },

    offAddClassFn() {
      this.dialogVisible1 = false;
    },

    //新建分类
    AddTeachPlanFn() {
      let that = this;
      console.log("this.from==>", this.form);
      if (this.form.typeName === "") {
        this.$message({
          message: "请填写分类名称",
          type: "error",
        });
        return;
      }

      AddTeachPlan({
        fatherId: that.form.fatherId,
        label: that.form.typeName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("新建分类=>", res.Data);

          // that.GetAllTeachPlanByOneFn()//大级

          //添加 大级
          if (that.form.fatherId == that.form.chooseType) {
            that.GetAllTeachPlanByOneFn();
          }

          if (that.uploadForm.uploadRow.isLeaf === 0) {
            console.log("that.form.isLeaf===0");
            that.teachData[that.uploadForm.uploadIndex][0].children.push(
              res.Data
            );
          }

          //一级的加号
          if (that.uploadForm.uploadRow.isLeaf === 1) {
            console.log("that.form.isLeaf===1");
            that.teachData[that.uploadForm.uploadIndex][0].children.push(
              res.Data
            );
          }

          //二级的加号
          if (that.uploadForm.uploadRow.isLeaf === 2) {
            console.log("that.form.isLeaf===2");
            let inx = that.teachData[
              that.uploadForm.uploadIndex
            ][0].children.findIndex((e) => {
              return e.Id === that.uploadForm.uploadRow.Id;
            });
            console.log("inx==>", inx);
            that.teachData[that.uploadForm.uploadIndex][0].children[
              inx
            ].children.push(res.Data);
          }

          this.dialogVisible1 = false;
          this.$message({
            message: "添加成功",
            type: "success",
          });

          that.form.typeName = "";
          that.uploadForm.uploadIndex = "";
          that.uploadForm.uploadRow = "";
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    //打开修改教案
    // openEditClassFn(e) {
    //   console.log("ee==>", e);
    //   this.form = e;
    //   this.dialogVisible2 = true;
    // },
    // offEditClassFn() {
    //   this.dialogVisible2 = false;
    // },
    // 分类

    openEditClassFn() {
      if (this.form.chooseType === "") {
        this.$message.error("未选择选择分类");
        return;
      }
      let index = this.PlanData.findIndex((e) => {
        return e.Id == this.form.chooseType;
      });
      console.log("index==>", index);
      this.form.chooseTypeId = this.PlanData[index].Id;
      console.log("this.form.chooseTypeId==>", this.form.chooseTypeId);
      this.dialogVisible2 = true;
      console.log("打开修改分类");
    },
    offEditClassFn() {
      this.dialogVisible2 = false;
    },

    //下面分类编辑
    openEditClassFn6(data, index) {
      console.log("index==>", index);

      let that = this;

      this.form.typeName = data.label;

      this.uploadForm.uploadRow = data;

      this.uploadForm.editIndex = index;

      this.form.chooseTypeId = data.Id;

      this.form.fatherId = data.fatherId;

      this.dialogVisible6 = true;
    },
    offEditClassFn6() {
      this.dialogVisible6 = false;
    },

    //修改分类名称 用
    UpdateTeachPlanFn6() {
      let that = this;

      console.log("that.form6==>", that.form);

      UpdateTeachPlan(
        { Id: that.form.chooseTypeId },
        {
          fatherId: that.form.fatherId,
          label: that.form.typeName,
        }
      ).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          // console.log("修改分类==>", res.Data);

          that.$message({
            message: "修改成功",
            type: "success",
          });
          that.dialogVisible6 = false;

          // 一级 类型 编辑
          if (that.uploadForm.uploadRow.isLeaf === 1) {
            console.log("修改分类大级1==>", res.Data);
            let data = that.teachData[that.uploadForm.editIndex][0];
            console.log("data==>", data);
            data.label = res.Data.label;
            data.fatherId = res.Data.fatherId;
            data.Id = res.Data.Id;
            data.isLeaf = 1;
          }

          // 二级 类型 编辑
          if (that.uploadForm.uploadRow.isLeaf === 2) {
            console.log("修改分类二级==>", res.Data);
            let inx = that.teachData[
              that.uploadForm.editIndex
            ][0].children.findIndex((e) => {
              return e.Id === that.uploadForm.uploadRow.Id;
            });
            console.log("inx==>", inx);
            that.teachData[that.uploadForm.editIndex][0].children[inx].label =
              res.Data.label;
            that.teachData[that.uploadForm.editIndex][0].children[
              inx
            ].fatherId = res.Data.fatherId;
            that.teachData[that.uploadForm.editIndex][0].children[inx].Id =
              res.Data.Id;
          }

          // 三级 类型 编辑
          if (that.uploadForm.uploadRow.isLeaf === 3) {
            let data = that.teachData[that.uploadForm.editIndex][0].children;
            console.log("data==>", data);
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              // console.log("element==>", element.children);
              for (let j = 0; j < element.children.length; j++) {
                const element2 = element.children[j];
                console.log(
                  "that.form.uploadRow.Id==>",
                  that.form.uploadRow.Id
                );
                console.log("element2==>", element2);

                if (that.uploadForm.uploadRow.Id == element2.Id) {
                  element2.label = res.Data.label;
                  element2.fatherId = res.Data.fatherId;
                  element2.Id = res.Data.Id;
                }
              }
            }
          }

          return;

          that.GetAllTeachPlanFn(); //获取所有教案
          // that.GetAllTeachPlanByOneFn(); //id获取所有教案

          console.log("666==>", that.teachData);

          let arr = that.teachData;
          if (that.form.twoIndex == -1) {
            // console.log("111");
            arr[Number(that.form.editIndex)][0].label = that.form.typeName;
            // console.log("that.teachData.children[Number(that.form.editIndex)]==>",that.teachData[Number(that.form.editIndex)][0]);
          } else {
            // console.log("222");
            arr[Number(that.form.editIndex)][0].children[
              Number(that.form.twoIndex)
            ].label = that.form.typeName;
            // console.log("that.teachData[Number(that.form.editIndex)][Number(that.form.twoIndex)]==>",that.teachData[Number(that.form.editIndex)][Number(that.form.twoIndex)]);
          }

          that.teachData = arr;

          that.form.chooseTypeId = "";
          that.form.fatherId = "";
          that.form.typeName = "";
          that.form.editIndex = "";
          that.form.twoIndex = "";

          console.log("that.teachData==>", that.teachData);
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    // // 打开上传教案 原
    // openUploadFn(row, index, year, month, week) {
    //   console.log("上传教案==>", row, index);
    //   console.log("上传教案 年==>", year);
    //   console.log("上传教案 月==>", month);
    //   console.log("上传教案 周==>", week);
    //   this.uploadForm.uploadIndex = index;
    //   this.uploadForm.uploadYear = year;
    //   this.uploadForm.uploadMonth = month;
    //   this.uploadForm.uploadWeek = week;
    //   this.dialogVisible3 = true;
    //   this.form = row;
    //   this.HomeWorkData = [];
    //   this.imgData = [];
    // },
    // offUploadFn() {
    //   this.dialogVisible3 = false;
    //   this.uploadIndex = "";
    //   this.uploadForm.uploadYear = "";
    //   this.uploadForm.uploadMonth = "";
    //   this.uploadForm.uploadYear = "";
    // },

    // 打开上传教案
    openUploadFn(row, index, id, label) {
      console.log("上传教案==>", row, index, id, label);

      // 一级上传
      if (row.isLeaf == 1) {
        this.uploadForm.uploadIndex = index;
        this.uploadForm.uploadId = id;
        this.uploadForm.uploadRow = row;
        this.form.PlanId = id;
        this.uploadForm.uploadName = label;
      }
      // 二级上传
      if (row.isLeaf == 2) {
        this.uploadForm.uploadIndex = index;
        this.uploadForm.uploadId = id;
        this.uploadForm.uploadRow = row;
        this.form.PlanId = id;
        this.uploadForm.uploadName = label;
      }
      // 三级上传
      if (row.isLeaf == 3) {
        this.uploadForm.uploadIndex = index;
        this.uploadForm.uploadId = id;
        this.uploadForm.uploadRow = row;
        this.form.PlanId = id;
        this.uploadForm.uploadName = label;
      }

      this.form.FileName = "";
      this.HomeWorkData = [];
      this.imgData = [];
      this.dialogVisible3 = true;

      // if (row.isLeaf == true) {
      //   this.uploadForm.uploadIndex = index;
      //   this.uploadForm.uploadId = id;
      //   this.uploadForm.uploadName = label;
      //   this.dialogVisible3 = true;
      //   this.form.uploadRow = row;
      //   this.HomeWorkData = [];
      //   this.imgData = [];
      //   this.form.PlanId = id;
      // }
      // if (row.isLeaf == false) {
      //   let inx = this.teachData[index][0].children.findIndex((e) => {
      //     return e.Id == row.Id;
      //   });
      //   this.uploadForm.uploadName = label;
      //   console.log("inx==>", inx);
      //   this.uploadForm.uploadIndex = index;
      //   this.form.twoIndex = inx;
      //   this.uploadForm.uploadId = id;
      //   this.dialogVisible3 = true;
      //   this.form.uploadRow = row;
      //   this.HomeWorkData = [];
      //   this.imgData = [];
      //   this.form.PlanId = id;
      // }
    },
    offUploadFn() {
      this.dialogVisible3 = false;
      this.uploadForm.uploadIndex = "";
      this.uploadForm.uploadId = "";
      this.uploadForm.uploadName = "";
      this.HomeWorkData = [];
      this.uploadForm.uploadRow = "";
      this.imgData = [];
    },

    // 打开上传教案 大
    openUploadFn7(row, index) {
      console.log("上传教案==>", row[0], index);
      this.uploadForm.uploadIndex = index;
      this.uploadForm.uploadRow = row[0];
      this.uploadForm.uploadId = row[0].fatherId;
      this.uploadForm.uploadName = row[0].label;

      this.form.fatherId = row[0].fatherId;
      this.form.FileName = "";
      this.HomeWorkData = [];
      this.imgData = [];
      this.dialogVisible7 = true;
    },

    offUploadFn7() {
      this.dialogVisible7 = false;
      this.uploadForm.uploadIndex = "";
      this.uploadForm.uploadId = "";
      this.uploadForm.uploadRow = "";
      this.uploadForm.uploadName = "";
      this.HomeWorkData = [];
      this.imgData = [];
    },

    //上传小教案 最大级上传
    AddPlanDetailFn7() {
      let that = this;
      console.log("this.from==>", this.form);
      if (this.form.FileName == "") {
        this.$message({
          message: "请输入小教案名称",
          type: "error",
        });
        return;
      }
      // if (this.imgData.length == 0) {
      //   this.$message({
      //     message: "请上传图片",
      //     type: "error",
      //   });
      //   return;
      // }
      // if (this.HomeWorkData.length == 0) {
      //   this.$message({
      //     message: "请上传作业",
      //     type: "error",
      //   });
      //   return;
      // }
      AddPlanDetail({
        Id: 0,
        FileName: that.form.FileName,
        PictureUrl: that.imgData,
        AddTime: "2023-03-04T03:04:33.564Z",
        HomeWorkUrl: that.HomeWorkData,
        PlanId: that.uploadForm.uploadId,
        PlanName: that.uploadForm.uploadName,
        MaterialUrls: [],
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("添加小教案==>", res.Data);

          that.teachData[that.uploadForm.uploadIndex][0].teachPlans.push({
            Id: res.Data.Id,
            FileName: res.Data.FileName,
            PictureUrl: res.Data.PictureUrl,
            AddTime: res.Data.AddTime,
            HomeWorkUrl: res.Data.HomeWorkUrl,
            PlanId: res.Data.PlanId,
            PlanName: res.Data.PlanName,
            // isLeaf: 0,
          });

          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.dialogVisible7 = false;
          that.uploadForm.uploadRow = "";
          that.uploadForm.uploadIndex = "";
          that.form.FileName = "";
          that.HomeWorkData = [];
          that.imgData = [];
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    //上传小教案
    AddPlanDetailFn() {
      let that = this;
      console.log("this.from==>", this.form);
      if (this.form.FileName == "") {
        this.$message({
          message: "请输入小教案名称",
          type: "error",
        });
        return;
      }
      // if (this.imgData.length == 0) {
      //   this.$message({
      //     message: "请上传图片",
      //     type: "error",
      //   });
      //   return;
      // }
      // if (this.HomeWorkData.length == 0) {
      //   this.$message({
      //     message: "请上传作业",
      //     type: "error",
      //   });
      //   return;
      // }
      AddPlanDetail({
        Id: 0,
        FileName: that.form.FileName,
        PictureUrl: that.imgData,
        AddTime: "2023-03-04T03:04:33.564Z",
        HomeWorkUrl: that.HomeWorkData,
        PlanId: that.uploadForm.uploadId,
        PlanName: that.uploadForm.uploadName,
        MaterialUrls: [],
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("添加小教案==>", res.Data);

          console.log(
            "that.uploadForm.uploadRow==>",
            that.uploadForm.uploadRow
          );

          // 一级的上传
          if (that.uploadForm.uploadRow.isLeaf === 1) {
            console.log("that.form.isLeaf===1");
            that.teachData[that.uploadForm.uploadIndex][0].children.push({
              label: res.Data.FileName,
              children: [],
              AddTime: res.Data.AddTime,
              FileName: res.Data.FileName,
              HomeWorkUrl: res.Data.HomeWorkUrl,
              Id: res.Data.Id,
              PictureUrl: res.Data.PictureUrl,
              PlanName: res.Data.PlanName,
            });
          }

          // 二级的上传
          if (that.uploadForm.uploadRow.isLeaf === 2) {
            let inx = that.teachData[
              that.uploadForm.uploadIndex
            ][0].children.findIndex((e) => {
              return e.Id === that.uploadForm.uploadRow.Id;
            });
            console.log("inx==>", inx);
            that.teachData[that.uploadForm.uploadIndex][0].children[
              inx
            ].children.push({
              label: res.Data.FileName,
              children: [],
              AddTime: res.Data.AddTime,
              FileName: res.Data.FileName,
              HomeWorkUrl: res.Data.HomeWorkUrl,
              Id: res.Data.Id,
              PictureUrl: res.Data.PictureUrl,
              PlanName: res.Data.PlanName,
            });
          }

          // 三级的上传
          if (that.uploadForm.uploadRow.isLeaf === 3) {
            let data = that.teachData[that.uploadForm.uploadIndex][0].children;
            console.log("data==>", data);
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              // console.log("element==>", element.children);
              for (let j = 0; j < element.children.length; j++) {
                const element2 = element.children[j];
                // console.log(
                //   "that.form.uploadRow.Id==>",
                //   that.form.uploadRow.Id
                // );
                console.log("三级的上传element2==>", element2);

                if (that.uploadForm.uploadRow.Id == element2.Id) {
                  element2.children.push({
                    label: res.Data.FileName,
                    children: [],
                    AddTime: res.Data.AddTime,
                    FileName: res.Data.FileName,
                    HomeWorkUrl: res.Data.HomeWorkUrl,
                    Id: res.Data.Id,
                    PictureUrl: res.Data.PictureUrl,
                    PlanName: res.Data.PlanName,
                  });
                  that.form.Id = res.Data.Id;
                  that.form.FileName = res.Data.FileName;
                  that.HomeWorkData = res.Data.HomeWorkUrl;
                  that.imgData = res.Data.PictureUrl;
                }
              }
            }
          }

          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.dialogVisible3 = false;
          that.uploadForm.uploadRow = "";
          that.uploadForm.uploadIndex = "";
          // that.form.FileName = "";
          // that.HomeWorkData = [];
          // that.imgData = [];

          return;
          if (that.form.uploadRow.isLeaf == true) {
            console.log("666");
            that.teachData[
              Number(that.uploadForm.uploadIndex)
            ][0].children.push({
              label: res.Data.FileName,
              children: [],
              AddTime: res.Data.AddTime,
              FileName: res.Data.FileName,
              HomeWorkUrl: res.Data.HomeWorkUrl,
              Id: res.Data.Id,
              PictureUrl: res.Data.PictureUrl,
              PlanName: res.Data.PlanName,
            });
          }
          if (that.form.uploadRow.isLeaf == false) {
            console.log("666");
            that.teachData[Number(that.uploadForm.uploadIndex)][0].children[
              Number(that.form.twoIndex)
            ].children.push({
              label: res.Data.FileName,
              children: [],
              AddTime: res.Data.AddTime,
              FileName: res.Data.FileName,
              HomeWorkUrl: res.Data.HomeWorkUrl,
              Id: res.Data.Id,
              PictureUrl: res.Data.PictureUrl,
              PlanName: res.Data.PlanName,
            });
          }
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.dialogVisible3 = false;
          // that.GetAllPlanDetailFn();

          // that.GetAllTeachPlanFn(); //获取所有教案
          // that.teachData = res.Data;
          that.form.Id = "";
          that.form.FileName = "";
          // that.HomeWorkUrl = "";
          that.HomeWorkUrl = [];
          that.uploadForm.uploadName = "";
          that.uploadForm.uploadId = "";
          that.imgData = [];
          that.uploadForm.uploadIndex = "";
          that.form.uploadRow = "";
          // that.$nextTick(() => {
          //   that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
          // });
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    // 打开编辑小教案
    openEditSmallFn(e, index) {
      console.log("编辑小教案23==>", e);
      console.log("编辑小教案==>", this.form);
      console.log("编辑小教案 下标==>", index);

      // 二级 小教案 !=-1
      let inx = this.teachData[index][0].children.findIndex((h) => {
        return e.Id == h.Id;
      });
      console.log("inx==>", inx);

      if (inx != -1) {
        this.form.twoIndex = inx;
        this.uploadForm.uploadIndex = index;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        this.imgData = e.PictureUrl;
        this.HomeWorkData = e.HomeWorkUrl;
        this.form.uploadRow = e;
      }
      if (inx == -1) {
        this.form.twoIndex = inx;
        this.uploadForm.uploadIndex = index;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        this.imgData = e.PictureUrl;
        this.HomeWorkData = e.HomeWorkUrl;
        this.form.uploadRow = e;
      }

      this.dialogVisible4 = true;

      return;
      if (inx != -1) {
        this.form.twoIndex = inx;
        this.uploadForm.uploadIndex = index;
        this.dialogVisible4 = true;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        this.imgData = e.PictureUrl;
        this.HomeWorkData = e.HomeWorkUrl;
      } else {
        this.form.twoIndex = inx;
        this.form.uploadRow = e;
        this.uploadForm.uploadIndex = index;
        this.dialogVisible4 = true;
        // this.form = e;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        // this.form.label = e.label;
        // this.form.children = e.children;
        // this.form.PlanName = e.PlanName;
        // this.form.PlanId = e.fatherId;
        // this.form.AddTime = e.AddTime;
        this.imgData = e.PictureUrl;
        // this.HomeWorkUrl = e.HomeWorkUrl;
        this.HomeWorkData = e.HomeWorkUrl;
      }

      // this.form.PlanId = e.PlanId;
      console.log("this.imgData==>", this.imgData);
    },
    offEditSmallFn() {
      this.dialogVisible4 = false;
      this.form.twoIndex = "";
      this.uploadForm.uploadIndex = "";
      this.form.Id = "";
      this.form.FileName = "";
      this.imgData = "";
      this.HomeWorkData = "";
      this.form.AddTime = "";
      this.form.children = "";
    },

    // 打开编辑小教案
    openEditSmallFn10(e, index) {
      console.log("编辑小教案一级==>", e);
      console.log("编辑小教案==>", this.form);
      console.log("编辑小教案 下标==>", index);

      // 一级 小教案 !=-1
      let inx = this.teachData[index][0].teachPlans.findIndex((h) => {
        return e.Id == h.Id;
      });
      console.log("inx==>", inx);

      if (inx != -1) {
        this.form.twoIndex = inx;
        this.uploadForm.uploadIndex = index;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        this.imgData = e.PictureUrl;
        this.HomeWorkData = e.HomeWorkUrl;
        this.form.uploadRow = e;
        this.imageData = this.imgData;
        this.imageData.forEach((item, index) => {
          item.name = index;
        });
        this.HomeData = this.HomeWorkData;
        this.HomeData.forEach((item, index) => {
          item.name = index;
        });
      }
      if (inx == -1) {
        this.form.twoIndex = inx;
        this.uploadForm.uploadIndex = index;
        this.form.Id = e.Id;
        this.form.FileName = e.FileName;
        this.imgData = e.PictureUrl;
        this.HomeWorkData = e.HomeWorkUrl;
        this.form.uploadRow = e;
      }

      this.dialogVisible10 = true;
    },
    offEditSmallFn10() {
      this.dialogVisible10 = false;
      this.form.twoIndex = "";
      this.uploadForm.uploadIndex = "";
      this.form.Id = "";
      this.form.FileName = "";
      this.imgData = "";
      this.HomeWorkData = "";
      this.form.AddTime = "";
      this.form.children = "";
    },

    //编辑小教案 0级
    UpdatePlanDetailFn10() {
      let that = this;
      console.log("this.from一级==>", this.form);
      if (this.form.FileName == "") {
        this.$message({
          message: "请输入小教案名称",
          type: "error",
        });
        return;
      }
      // if (this.imgData.length == 0) {
      //   this.$message({
      //     message: "请上传图片",
      //     type: "error",
      //   });
      //   return;
      // }
      // if (this.HomeWorkData.length == 0) {
      //   this.$message({
      //     message: "请上传作业",
      //     type: "error",
      //   });
      //   return;
      // }

      UpdatePlanDetail({
        Id: that.form.Id,
        FileName: that.form.FileName,
        PictureUrl: that.imgData,
        // AddTime: that.form.AddTime,
        // label: that.form.FileName,
        PlanName: that.form.PlanName,
        // children:that.form.children,
        // Year: that.uploadForm.uploadYear,
        // Month: that.uploadForm.uploadMonth,
        // Week: that.uploadForm.uploadWeek,
        // HomeWorkUrl: that.HomeWorkUrl,
        HomeWorkUrl: that.HomeWorkData,
        // PlanId: that.form.PlanId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("编辑小教案==>", res.Data);

          console.log("that.form.twoIndex一级==>", that.form.twoIndex);

          if (that.form.twoIndex != -1) {
            let arr =
              that.teachData[that.uploadForm.uploadIndex][0].teachPlans[
                that.form.twoIndex
              ];
            console.log("arr==>", arr);
            arr.label = res.Data.FileName;
            arr.AddTime = res.Data.AddTime;
            arr.FileName = res.Data.FileName;
            arr.HomeWorkUrl = res.Data.HomeWorkUrl;
            arr.Id = res.Data.Id;
            arr.PictureUrl = res.Data.PictureUrl;
            arr.PlanName = res.Data.PlanName;
            that.teachData[that.uploadForm.uploadIndex][0].teachPlans[
              that.form.twoIndex
            ] = arr;
          }

          that.form.Id = "";
          that.form.FileName = "";
          that.form.PlanId = "";
          that.form.AddTime = "";
          that.form.PlanName = "";
          that.form.children = [];
          that.uploadForm.uploadIndex = [];
          that.form.twoIndex = [];
          that.form.label = "";
          that.imgData = [];
          // that.HomeWorkUrl = "";
          that.HomeWorkData = [];
          that.imgData = [];

          that.dialogVisible10 = false;
          that.$message({
            message: "编辑成功",
            type: "success",
          });
        } else {
          that.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    //编辑小教案 123级
    UpdatePlanDetailFn() {
      let that = this;
      console.log("this.from==>", this.form);
      if (this.form.FileName == "") {
        this.$message({
          message: "请输入小教案名称",
          type: "error",
        });
        return;
      }
      // if (this.imgData.length == 0) {
      //   this.$message({
      //     message: "请上传图片",
      //     type: "error",
      //   });
      //   return;
      // }
      // if (this.HomeWorkData.length == 0) {
      //   this.$message({
      //     message: "请上传作业",
      //     type: "error",
      //   });
      //   return;
      // }

      UpdatePlanDetail({
        Id: that.form.Id,
        FileName: that.form.FileName,
        PictureUrl: that.imgData,
        // AddTime: that.form.AddTime,
        // label: that.form.FileName,
        PlanName: that.form.PlanName,
        // children:that.form.children,
        // Year: that.uploadForm.uploadYear,
        // Month: that.uploadForm.uploadMonth,
        // Week: that.uploadForm.uploadWeek,
        // HomeWorkUrl: that.HomeWorkUrl,
        HomeWorkUrl: that.HomeWorkData,
        // PlanId: that.form.PlanId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("编辑小教案==>", res.Data);

          console.log("that.form.twoIndex==>", that.form.twoIndex);

          // 编辑 二级的小教案
          if (that.form.twoIndex != -1) {
            let arr =
              that.teachData[that.uploadForm.uploadIndex][0].children[
                that.form.twoIndex
              ];
            arr.label = res.Data.FileName;
            arr.AddTime = res.Data.AddTime;
            arr.FileName = res.Data.FileName;
            arr.HomeWorkUrl = res.Data.HomeWorkUrl;
            arr.Id = res.Data.Id;
            arr.PictureUrl = res.Data.PictureUrl;
            arr.PlanName = res.Data.PlanName;

            that.teachData[that.uploadForm.uploadIndex][0].children[
              that.form.twoIndex
            ] = arr;
          }

          // 编辑 三级的小教案
          if (that.form.twoIndex == -1) {
            console.log("twoIndex==>", that.form.twoIndex);
            let data = that.teachData[that.uploadForm.uploadIndex][0].children;
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              console.log("element==>", element.children);
              for (let j = 0; j < element.children.length; j++) {
                const element2 = element.children[j];
                console.log("element2==>", element2);

                if (that.form.uploadRow.Id == element2.Id) {
                  console.log("编辑element2==>", element2);
                  element2.label = res.Data.FileName;
                  element2.AddTime = res.Data.AddTime;
                  element2.FileName = res.Data.FileName;
                  element2.HomeWorkUrl = res.Data.HomeWorkUrl;
                  element2.Id = res.Data.Id;
                  element2.PictureUrl = res.Data.PictureUrl;
                  element2.PlanName = res.Data.PlanName;
                }

                //四级
                for (let p = 0; p < element2.children.length; p++) {
                  const element3 = element2.children[p];
                  if (element3.Id == that.form.uploadRow.Id) {
                    element3.label = res.Data.FileName;
                    element3.AddTime = res.Data.AddTime;
                    element3.FileName = res.Data.FileName;
                    element3.HomeWorkUrl = res.Data.HomeWorkUrl;
                    element3.Id = res.Data.Id;
                    element3.PictureUrl = res.Data.PictureUrl;
                    element3.PlanName = res.Data.PlanName;
                  }
                }
              }
            }
          }

          //编辑四级的小教案
          // if()

          // if (that.form.twoIndex == -1) {
          //       let data = that.teachData[index][0].children;
          //       for (let i = 0; i < data.length; i++) {
          //         const element = data[i];
          //         for (let j = 0; j < element.children.length; j++) {
          //           const element2 = element.children[j];
          //           console.log("element2==>", element2);
          //           let inx2 = element.children.findIndex((e) => {
          //             return e.Id == that.uploadForm.uploadRow.Id;
          //           });
          //           console.log("inx2==>", inx2);

          //         }
          //       }
          //     }

          // that.GetAllTeachPlanFn(); //获取所有教案
          // that.teachData = res.Data;
          // that.GetAllPlanDetailFn();
          that.form.Id = "";
          that.form.FileName = "";
          that.form.PlanId = "";
          that.form.AddTime = "";
          that.form.PlanName = "";
          that.form.children = [];
          that.uploadForm.uploadIndex = [];
          that.form.twoIndex = [];
          that.form.label = "";
          that.imgData = [];
          // that.HomeWorkUrl = "";
          that.HomeWorkData = [];
          that.imgData = [];

          that.dialogVisible4 = false;
          that.$message({
            message: "编辑成功",
            type: "success",
          });

          // 6666666
          // that.teachData[Year].treeData[Month].children[Week].children[
          //   clickIndex
          // ].label = res.Data.FileName;
          // that.$nextTick(() => {
          //   that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
          // });
        } else {
          that.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    //修改分类名称 无用
    UpdateTeachPlanFn() {
      let that = this;
      if (that.form.chooseType == "") {
        that.$message({
          message: "未选择分类",
          type: "error",
        });
        return;
      }

      UpdateTeachPlan(
        { Id: that.form.chooseTypeId },
        {
          fatherId: 0,
          label: that.form.typeName,
        }
      ).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("修改分类==>", res.Data);
          this.dialogVisible2 = false;
          this.$message({
            message: "修改成功",
            type: "success",
          });
          that.GetAllTeachPlanFn(); //获取所有教案
          that.form.chooseTypeId = "";
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    // 删除教案、分类
    DeleteTeachPlanFn(row, index) {
      let that = this;

      console.log("删除教案数据row==>", row, index);

      if (that.form.chooseType === "") {
        that.$message({
          message: "未选择分类",
          type: "error",
        });
        return;
      }

      that
        .$confirm("确定删除该信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeleteTeachPlan({
            Id: row.Id ? row.Id : that.form.chooseType,
          }).then((res) => {
            console.log(" 管理员删除教案消息==>", res);
            if (res.Code === 2000) {
              // 删除大板块 一级
              if (row.isLeaf === 1) {
                that.GetAllTeachPlanByOneFn();
              }

              // 删除二级
              if (row.isLeaf == 2) {
                let inx = that.teachData[index][0].children.findIndex((h) => {
                  return row.Id == h.Id;
                });
                console.log("删除二级inx==>", inx);
                that.teachData[index][0].children.splice(inx, 1);
              }

              that.$message({
                type: "success",
                message: "删除成功",
              });

              return;

              // that.GetAllTeachPlanFn();
              if (that.form.twoIndex != -1) {
                that.teachData[index][0].children.splice(that.form.twoIndex, 1);
              } else {
                that.teachData.splice(index, 1);
              }
              that.form.chooseType = "";
              that.form.twoIndex = "";
              //     that.$nextTick(() => {
              //   that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
              // });
              that.GetAllTeachPlanFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 删除小教案 用 0级
    DeletePlanDetailFn2(row, index) {
      let that = this;
      console.log("删除下标==>", index);

      that
        .$confirm("确定删除该教案信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeletePlanDetail({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除教案==>", res);
            if (res.Code === 2000) {
              let inx = that.teachData[index][0].teachPlans.findIndex((e) => {
                return e.Id == row.Id;
              });

              that.teachData[index][0].teachPlans.splice(inx, 1);

              that.$message({
                type: "success",
                message: "删除成功",
              });

              // that.GetAllTeachPlanByOneFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除小教案 用 123级
    DeletePlanDetailFn(row, index) {
      let that = this;
      console.log("删除下标==>", index);

      let inx = this.teachData[index][0].children.findIndex((e) => {
        return e.Id == row.Id;
      });
      console.log("删除下标inx==>", inx);

      that
        .$confirm("确定删除该教案信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeletePlanDetail({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除教案==>", res);
            if (res.Code === 2000) {
              // 删除二级的小教案
              if (inx != -1) {
                that.teachData[index][0].children.splice(inx, 1);
              }

              if (inx == -1) {
                let data = that.teachData[index][0].children;
                for (let i = 0; i < data.length; i++) {
                  const element = data[i];
                  for (let j = 0; j < element.children.length; j++) {
                    const element2 = element.children[j];
                    console.log("element2==>", element2);
                    let inx2 = element.children.findIndex((e) => {
                      return e.Id == row.Id;
                    });
                    console.log("inx2==>", inx2);
                    if (inx2 != -1) {
                      element.children.splice(inx2, 1);
                    }

                    let inx3 = element2.children.findIndex((l) => {
                      return l.Id == row.Id;
                    });
                    if (inx3 != -1) {
                      element2.children.splice(inx3, 1);
                    }
                  }
                }
              }

              that.$message({
                type: "success",
                message: "删除成功",
              });

              // that.GetAllTeachPlanByOneFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除小教案 不用
    DeletePlanDetailFn3(row, index) {
      let that = this;
      let Year = row.Year;
      let Month = row.Month - 1;
      let Week = row.Week - 1;
      let clickIndex = that.teachData[Year].treeData[Month].children[
        Week
      ].children.findIndex((e) => {
        return e.Id == row.Id;
      });
      // console.log("删除下标==>",clickIndex);
      // console.log("删除==>", that.teachData[Year].treeData[Month].children[Week]);

      // return
      that
        .$confirm("确定删除该教案信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeletePlanDetail({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除教案==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "删除成功",
              });

              that.teachData[Year].treeData[Month].children[
                Week
              ].children.splice(clickIndex, 1);

              // that.GetAllPlanDetailFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //获取所有分类
    GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案==>", res.Data);
          // that.teachData = res.Data;
          that.PlanData = res.Data;
        }
      });
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.show-hide:hover :nth-child(2) {
  display: inline-block !important;
}
.pobutton {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
